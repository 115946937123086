
// acid import Routes......
const acidImport = '/acid-import/application-process/'
export const acidImportApplicationListApi = acidImport + 'application-list'
export const acidImportApplicationForwardListApi = acidImport + 'forward-list'
export const designationWiseUser = acidImport + 'user-list'
export const acidImportForwardApi = acidImport + 'forward'
export const acidImportInspectionListApi = acidImport + 'inspection-list'
export const acidImportApprovalListApi = acidImport + 'approval-list'
export const acidImportApprovedListApi = acidImport + 'approved-list'
export const acidImportApprovedSubmitApi = acidImport + 'approved-submit'
export const acidImportRejectedListApi = acidImport + 'rejected-list'
export const acidImportRejectApi = acidImport + 'reject'
export const acidImportInpectionSubmitApi = acidImport + 'inspection-submit'
